import React from 'react';
import './PrivacyPage.scss';

class PrivacyPage extends React.Component {
  render() {
    return (
      <div className="container section privacy">
        <div>
          <h1>Privacy Policy</h1>
          <p>
            <span>Effective date: 09/30/2024</span>
          </p>
          <ol>
            <li>
              <div className="legal-section-header">Introduction</div>
              <p>
                <span>Welcome to&nbsp;</span>
                <b>Hookshot Software Limited</b>
                <span>.</span>
              </p>
              <p>
                <span>
                  Hookshot Software Limited&nbsp;(“us”, “we”, or “our”)
                  operates&nbsp;
                </span>
                <span>
                  <a href="https://hookshot.ca/">https://hookshot.ca/</a>
                </span>
                &nbsp;,&nbsp;
                <span>
                  <a href="https://nextsailing.ca/">https://nextsailing.ca/</a>
                </span>
                <span>&nbsp;, the </span>
                <span>
                  Easy Translator app&nbsp;, the Super Search app, and the Video
                  Clipper app (hereinafter referred to as “
                </span>
                <span>Service</span>
                <span>”).</span>
              </p>
              <p>
                <span>
                  Our Privacy Policy governs your visit
                  to&nbsp;https://hookshot.ca/, and explains how we collect,
                  safeguard and disclose information that results from your use
                  of our Service.{' '}
                </span>
              </p>
              <p>
                <span>
                  We use your data to provide and improve Service. By using
                  Service, you agree to the collection and use of information in
                  accordance with this policy. Unless otherwise defined in this
                  Privacy Policy, the terms used in this Privacy Policy have the
                  same meanings as in our Terms and Conditions.
                </span>
              </p>
              <p>
                <span>Our Terms and Conditions (“</span>
                <span>Terms</span>
                <span>
                  ”) govern all use of our Service and together with the Privacy
                  Policy constitutes your agreement with us (“
                </span>
                <span>agreement</span>
                <span>”).</span>
              </p>
            </li>
            <li>
              <div className="legal-section-header">Definitions</div>
              <p>
                <span>SERVICE&nbsp;</span>
                <span>
                  means the&nbsp;https://hookshot.ca/ website&nbsp;operated
                  by&nbsp;Hookshot Software Limited.
                </span>
              </p>
              <p>
                <span>PERSONAL DATA</span>
                <span>
                  &nbsp;means data about a living individual who can be
                  identified from those data (or from those and other
                  information either in our possession or likely to come into
                  our possession).
                </span>
              </p>
              <p>
                <span>USAGE DATA</span>
                <span>
                  &nbsp;is data collected automatically either generated by the
                  use of Service or from Service infrastructure itself (for
                  example, the duration of a page visit).
                </span>
              </p>
              <p>
                <span>COOKIES</span>
                <span>
                  &nbsp;are small files stored on your device (computer or
                  mobile device).
                </span>
              </p>
              <p>
                <span>DATA CONTROLLER</span>
                <span>
                  &nbsp;means a natural or legal person who (either alone or
                  jointly or in common with other persons) determines the
                  purposes for which and the manner in which any personal data
                  are, or are to be, processed. For the purpose of this Privacy
                  Policy, we are a Data Controller of your data.
                </span>
              </p>
              <p>
                <span>DATA PROCESSORS (OR SERVICE PROVIDERS)</span>
                <span>
                  &nbsp;means any natural or legal person who processes the data
                  on behalf of the Data Controller. We may use the services of
                  various Service Providers in order to process your data more
                  effectively.
                </span>
              </p>
              <p>
                <span>DATA SUBJECT&nbsp;</span>
                <span>
                  is any living individual who is the subject of Personal Data.
                </span>
              </p>
              <p>
                <span>THE USER&nbsp;</span>
                <span>
                  is the individual using our Service. The User corresponds to
                  the Data Subject, who is the subject of Personal Data.
                </span>
              </p>
            </li>
            <li>
              <div className="legal-section-header">
                Information Collection and Use
              </div>
              <p>
                <span>
                  We collect several different types of information for various
                  purposes to provide and improve our Service to you.
                </span>
              </p>
            </li>
            <li>
              <div className="legal-section-header">Personal Data</div>
              <p>
                <span>
                  While using our Service, we may ask you to provide us with
                  certain personally identifiable information that can be used
                  to contact or identify you (“
                </span>
                <span>Personal Data</span>
                <span>
                  ”). Personally identifiable information may include, but is
                  not limited to:
                </span>
              </p>
              <ol style={{ listStyleType: 'lower-latin' }}>
                <li>
                  <span>Email address</span>
                </li>
                <li>
                  <span>Phone number</span>
                </li>
                <li>
                  <span>Cookies and Usage Data</span>
                </li>
              </ol>
              <p>
                <span>
                  We may use your Personal Data to contact you with newsletters,
                  marketing or promotional materials and other information that
                  may be of interest to you. You may opt out of receiving any,
                  or all, of these communications from us&nbsp;by following the
                  unsubscribe link or by emailing at&nbsp;team@hookshot.ca.
                </span>
              </p>
              <p>
                <span>
                  Nextsailing.ca provides a service that sends an SMS
                  notification to a user-provided phone number with the user's
                  consent. Phone numbers and route subscriptions are
                  automatically deleted after 30 days.
                </span>
              </p>
              <p>
                <b>Usage Data</b>
              </p>
              <p>
                <span>
                  We may also collect information that your browser sends
                  whenever you visit our Service or when you access Service by
                  or through a mobile device (“
                </span>
                <span>Usage Data</span>
                <span>”).</span>
              </p>
              <p>
                <span>
                  This Usage Data may include information such as your
                  computer's Internet Protocol address (e.g. IP address),
                  browser type, browser version, the pages of our Service that
                  you visit, the time and date of your visit, the time spent on
                  those pages, unique device identifiers and other diagnostic
                  data.
                </span>
              </p>
              <p>
                <span>
                  When you access Service with a mobile device, this Usage Data
                  may include information such as the type of mobile device you
                  use, your mobile device unique ID, the IP address of your
                  mobile device, your mobile operating system, the type of
                  mobile Internet browser you use, unique device identifiers and
                  other diagnostic data.
                </span>
              </p>
              <p>
                <b>Tracking Cookies Data</b>
              </p>
              <p>
                <span>
                  We use cookies and similar tracking technologies to track the
                  activity on our Service and we hold certain information.
                </span>
              </p>
              <p>
                <span>
                  Cookies are files with a small amount of data which may
                  include an anonymous unique identifier. Cookies are sent to
                  your browser from a website and stored on your device. Other
                  tracking technologies are also used such as beacons, tags and
                  scripts to collect and track information and to improve and
                  analyze our Service.
                </span>
              </p>
              <p>
                <span>
                  You can instruct your browser to refuse all cookies or to
                  indicate when a cookie is being sent. However, if you do not
                  accept cookies, you may not be able to use some portions of
                  our Service.
                </span>
              </p>
              <p>
                <span>Examples of Cookies we use:</span>
              </p>
              <ol style={{ listStyleType: 'lower-latin' }}>
                <li>
                  <b>Session Cookies:</b>
                  <span>
                    &nbsp;We use Session Cookies to operate our Service.
                  </span>
                </li>
              </ol>
            </li>
            <li>
              <div className="legal-section-header">Use of Data</div>
              <p>
                <span>
                  Hookshot Software Limited&nbsp;uses the collected data for
                  various purposes:
                </span>
              </p>
              <ol style={{ listStyleType: 'lower-latin' }}>
                <li>
                  <span>to provide and maintain our Service;</span>
                </li>
                <li>
                  <span>to notify you about changes to our Service; </span>
                </li>
                <li>
                  <span>
                    to allow you to participate in interactive features of our
                    Service when you choose to do so;{' '}
                  </span>
                </li>
                <li>
                  <span>to provide customer support; </span>
                </li>
                <li>
                  <span>
                    to gather analysis or valuable information so that we can
                    improve our Service;{' '}
                  </span>
                </li>
                <li>
                  <span>to monitor the usage of our Service;</span>
                </li>
                <li>
                  <span>to detect, prevent and address technical issues;</span>
                </li>
                <li>
                  <span>
                    to fulfill any other purpose for which you provide it;
                  </span>
                </li>
                <li>
                  <span>
                    to carry out our obligations and enforce our rights arising
                    from any contracts entered into between you and us,
                    including for billing and collection;
                  </span>
                </li>
                <li>
                  <span>
                    to provide you with notices about your account and/or
                    subscription, including expiration and renewal notices,
                    email-instructions, etc.;
                  </span>
                </li>
                <li>
                  <span>
                    to provide you with news, special offers and general
                    information about other goods, services and events which we
                    offer that are similar to those that you have already
                    purchased or enquired about unless you have opted not to
                    receive such information;
                  </span>
                </li>
                <li>
                  <span>
                    in any other way we may describe when you provide the
                    information;
                  </span>
                </li>
                <li>
                  <span>for any other purpose with your consent. </span>
                </li>
              </ol>
            </li>
            <li>
              <div className="legal-section-header">Retention of Data</div>
              <p>
                <span>
                  We will retain your Personal Data only for as long as is
                  necessary for the purposes set out in this Privacy Policy. We
                  will retain and use your Personal Data to the extent necessary
                  to comply with our legal obligations (for example, if we are
                  required to retain your data to comply with applicable laws),
                  resolve disputes, and enforce our legal agreements and
                  policies.
                </span>
              </p>
              <p>
                <span>
                  We will also retain Usage Data for internal analysis purposes.
                  Usage Data is generally retained for a shorter period, except
                  when this data is used to strengthen the security or to
                  improve the functionality of our Service, or we are legally
                  obligated to retain this data for longer time periods.
                </span>
              </p>
            </li>
            <li>
              <div className="legal-section-header">Transfer of Data</div>
              <p>
                <span>
                  Your information, including Personal Data, may be transferred
                  to – and maintained on – computers located outside of your
                  state, province, country or other governmental jurisdiction
                  where the data protection laws may differ from those of your
                  jurisdiction.
                </span>
              </p>
              <p>
                <span>
                  If you are located outside United States and choose to provide
                  information to us, please note that we transfer the data,
                  including Personal Data, to United States and process it
                  there.
                </span>
              </p>
              <p>
                <span>
                  Your consent to this Privacy Policy followed by your
                  submission of such information represents your agreement to
                  that transfer.
                </span>
              </p>
              <p>
                <span>
                  Hookshot Software Limited&nbsp;will take all the steps
                  reasonably necessary to ensure that your data is treated
                  securely and in accordance with this Privacy Policy and no
                  transfer of your Personal Data will take place to an organi
                </span>
                <span>z</span>
                <span>
                  ation or a country unless there are adequate controls in place
                  including the security of your data and other personal
                  information.
                </span>
              </p>
            </li>
            <li>
              <div className="legal-section-header">Disclosure of Data</div>
              <p>
                <span>
                  We may disclose personal information that we collect, or you
                  provide:
                </span>
              </p>
              <ol style={{ listStyleType: 'lower-latin' }}>
                <li>
                  <span>Disclosure for Law Enforcement.</span>
                  <span>
                    Under certain circumstances, we may be required to disclose
                    your Personal Data if required to do so by law or in
                    response to valid requests by public authorities.
                  </span>
                </li>
                <li>
                  <span>Business Transaction.</span>
                  <p>
                    <span>
                      If we or our subsidiaries are involved in a merger,
                      acquisition or asset sale, your Personal Data may be
                      transferred.
                    </span>
                  </p>
                </li>
                <li>
                  <span>
                    Other cases. We may disclose your information also:
                  </span>
                  <ol style={{ listStyleType: 'lower-roman' }}>
                    <li>
                      <span>to our subsidiaries and affiliates;</span>
                    </li>
                    <li>
                      <span>
                        to contractors, service providers, and other third
                        parties we use to support our business;
                      </span>
                    </li>
                    <li>
                      <span>
                        to fulfill the purpose for which you provide it;
                      </span>
                    </li>
                    <li>
                      <span>
                        for any other purpose disclosed by us when you provide
                        the information;
                      </span>
                    </li>
                    <li>
                      <span>with your consent in any other cases;</span>
                    </li>
                    <li>
                      <span>
                        if we believe disclosure is necessary or appropriate to
                        protect the rights, property, or safety of the Company,
                        our customers, or others.
                      </span>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <div className="legal-section-header">Security of Data</div>
              <span>
                The security of your data is important to us but remember that
                no method of transmission over the Internet or method of
                electronic storage is 100% secure. While we strive to use
                commercially acceptable means to protect your Personal Data, we
                cannot guarantee its absolute security.
              </span>
            </li>
            <li>
              <div className="legal-section-header">
                Your Data Protection Rights Under General Data Protection
                Regulation (GDPR)
              </div>
              <p>
                <span>
                  If you are a resident of the European Union (EU) and European
                  Economic Area (EEA), you have certain data protection rights,
                  covered by GDPR. – See more at{' '}
                </span>
                <span>
                  <a href="https://eur-lex.europa.eu/eli/reg/2016/679/oj">
                    https://eur-lex.europa.eu/eli/reg/2016/679/oj
                  </a>
                </span>
              </p>
              <p>
                <span>
                  We aim to take reasonable steps to allow you to correct,
                  amend, delete, or limit the use of your Personal Data.
                </span>
              </p>
              <p>
                <span>
                  If you wish to be informed what Personal Data we hold about
                  you and if you want it to be removed from our systems, please
                  email us at team@hookshot.ca.{' '}
                </span>
              </p>
              <p>
                <span>
                  In certain circumstances, you have the following data
                  protection rights:
                </span>
              </p>
              <ol style={{ listStyleType: 'lower-latin' }}>
                <li>
                  <span>
                    the right to access, update or to delete the information we
                    have on you;
                  </span>
                </li>
                <li>
                  <span>
                    the right of rectification. You have the right to have your
                    information rectified if that information is inaccurate or
                    incomplete;
                  </span>
                </li>
                <li>
                  <span>
                    the right to object. You have the right to object to our
                    processing of your Personal Data;
                  </span>
                </li>
                <li>
                  <span>
                    the right of restriction. You have the right to request that
                    we restrict the processing of your personal information;
                  </span>
                </li>
                <li>
                  <span>
                    the right to data portability. You have the right to be
                    provided with a copy of your Personal Data in a structured,
                    machine-readable and commonly used format;
                  </span>
                </li>
                <li>
                  <span>
                    the right to withdraw consent.&nbsp;You also have the right
                    to withdraw your consent at any time where we rely on your
                    consent to process your personal information;
                  </span>
                </li>
              </ol>
              <p>
                <span>
                  Please note that we may ask you to verify your identity before
                  responding to such requests. Please note, we may not able to
                  provide Service without some necessary data.
                </span>
              </p>
              <p>
                <span>
                  You have the right to complain to a Data Protection Authority
                  about our collection and use of your Personal Data. For more
                  information, please contact your local data protection
                  authority in the European Economic Area (EEA).
                </span>
              </p>
            </li>
            <li>
              <div className="legal-section-header">
                Your Data Protection Rights under the California Privacy
                Protection Act (CalOPPA)
              </div>
              <span>
                CalOPPA is the first state law in the nation to require
                commercial websites and online services to post a privacy
                policy. The law’s reach stretches well beyond California to
                require a person or company in the United States (and
                conceivable the world) that operates websites collecting
                personally identifiable information from California consumers to
                post a conspicuous privacy policy on its website stating exactly
                the information being collected and those individuals with whom
                it is being shared, and to comply with this policy. – See more
                at:{' '}
              </span>
              <span>
                <a href="https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/">
                  https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/
                </a>
              </span>
              <p>
                <span>According to CalOPPA we agree to the following:</span>
              </p>
              <ol style={{ listStyleType: 'lower-latin' }}>
                <li>
                  <span>users can visit our site anonymously;</span>
                </li>
                <li>
                  <span>
                    our Privacy Policy link includes the word “Privacy”, and can
                    easily be found on the page specified above on the home page
                    of our website;
                  </span>
                </li>
                <li>
                  <span>
                    users will be notified of any privacy policy changes on our
                    Privacy Policy Page;
                  </span>
                </li>
                <li>
                  <span>
                    users are able to change their personal information by
                    emailing us at team@hookshot.ca.{' '}
                  </span>
                </li>
              </ol>
            </li>
            <li>
              <div className="legal-section-header">Service Providers</div>
              <span>
                We may employ third party companies and individuals to
                facilitate our Service (“
              </span>
              <span>Service Providers</span>
              <span>
                ”), provide Service on our behalf, perform Service-related
                services or assist us in analyzing how our Service is used.
              </span>
              <p>
                These third parties have access to your Personal Data only to
                perform these tasks on our behalf and are obligated not to
                disclose or use it for any other purpose.
              </p>
              <ol style={{ listStyleType: 'circle' }}>
                <li>
                  Amazon Web Services -{' '}
                  <a href="https://aws.amazon.com/privacy/">
                    https://aws.amazon.com/privacy/
                  </a>{' '}
                  and{' '}
                  <a href="https://aws.amazon.com/compliance/data-privacy-faq/">
                    https://aws.amazon.com/compliance/data-privacy-faq/
                  </a>
                </li>
                <li>
                  Cloudflare -{' '}
                  <a href="https://www.cloudflare.com/privacypolicy/">
                    https://www.cloudflare.com/privacypolicy/
                  </a>{' '}
                </li>
                <li>
                  Netlify -{' '}
                  <a href="https://www.netlify.com/privacy/">
                    https://www.netlify.com/privacy/
                  </a>{' '}
                </li>
                <li>
                  Deepgram -{' '}
                  <a href="https://deepgram.com/data-security">
                    https://deepgram.com/data-security
                  </a>{' '}
                  and{' '}
                  <a href="https://deepgram.com/privacy">
                    https://deepgram.com/privacy
                  </a>
                </li>
              </ol>
            </li>
            <li>
              <p>
                <div className="legal-section-header">Analytics</div>
                <div>
                  We may use third-party Service Providers to monitor and
                  analyze the use of our Service.
                </div>
              </p>
              <p>
                <b>Google Analytics</b>
                <p>Google Analytics is provided by Google LLC.</p>
              </p>
              <p>
                <b>Sentry</b>
                <p>
                  Sentry is an error-handling service provided by Sentry.io.
                </p>
              </p>
              <p>
                <b>Mixpanel</b>
                <p>
                  Mixpanel is provided by Mixpanel Inc. You can prevent Mixpanel
                  from using your information for analytics purposes by
                  opting-out. To opt-out of Mixpanel service, please email us at
                  team@hookshot.ca For more information on what type of
                  information Mixpanel collects, please visit the Terms of Use
                  page of Mixpanel:{' '}
                  <a
                    href="https://mixpanel.com/legal/terms-of-use"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {' '}
                    https://mixpanel.com/terms/{' '}
                  </a>
                </p>
              </p>
            </li>
            <li>
              <div className="legal-section-header">CI/CD tools</div>
              <p>
                <span>
                  We may use third-party Service Providers to automate the
                  development process of our Service.{' '}
                </span>
              </p>
              <p>
                <b>GitHub</b>
                <p>
                  GitHub is provided by GitHub, Inc. GitHub is a development
                  platform to host and review code, manage projects, and build
                  software. For more information on what data GitHub collects
                  for what purpose and how the protection of the data is
                  ensured, please visit GitHub Privacy Policy page:&nbsp;
                  <a
                    href="https://help.github.com/en/articles/github-privacy-statement
"
                  >
                    https://help.github.com/en/articles/github-privacy-statement
                  </a>
                  .
                </p>
              </p>
            </li>
            <li>
              <div className="legal-section-header">Payments</div>
              <p>
                <span>
                  We may provide paid products and/or services within Service.
                  In that case, we use third-party services for payment
                  processing (e.g. payment processors).
                </span>
              </p>
              <p>
                <span>
                  We will not store or collect your payment card details. That
                  information is provided directly to our third-party payment
                  processors whose use of your personal information is governed
                  by their Privacy Policy. These payment processors adhere to
                  the standards set by PCI-DSS as managed by the PCI Security
                  Standards Council, which is a joint effort of brands like
                  Visa, Mastercard, American Express and Discover. PCI-DSS
                  requirements help ensure the secure handling of payment
                  information.
                </span>
              </p>
              <p>
                <span>
                  The payment processor we work with is Stripe. Their Privacy
                  Policy can be viewed at:&nbsp;
                  <a href="https://stripe.com/us/privacy">
                    https://stripe.com/us/privacy
                  </a>
                </span>
              </p>
            </li>
            <li>
              <div className="legal-section-header">Links to Other Sites</div>
              <p>
                <span>
                  Our Service may contain links to other sites that are not
                  operated by us. If you click a third party link, you will be
                  directed to that third party's site. We strongly advise you to
                  review the Privacy Policy of every site you visit.
                </span>
              </p>
              <p>
                <span>
                  We have no control over and assume no responsibility for the
                  content, privacy policies or practices of any third party
                  sites or services.
                </span>
              </p>
            </li>
            <li>
              <div className="legal-section-header">Children's Privacy</div>
              <p>
                <span>
                  Our Services are not intended for use by children under the
                  age of 18 (“
                </span>
                <span>Child</span>
                <span>” or “</span>
                <span>Children</span>
                <span>”). </span>
              </p>
              <p>
                <span>
                  We do not knowingly collect personally identifiable
                  information from Children under 18. If you become aware that a
                  Child has provided us with Personal Data, please contact us.
                  If we become aware that we have collected Personal Data from
                  Children without verification of parental consent, we take
                  steps to remove that information from our servers.
                </span>
              </p>
            </li>
            <li>
              <div className="legal-section-header">
                Changes to this Privacy Policy
              </div>
              <p>
                <span>
                  We may update our Privacy Policy from time to time. We will
                  notify you of any changes by posting the new Privacy Policy on
                  this page.
                </span>
              </p>
              <p>
                <span>
                  We will let you know via email and/or a prominent notice on
                  our Service, prior to the change becoming effective and update
                  “effective date” at the top of this Privacy Policy.
                </span>
              </p>
              <p>
                <span>
                  You are advised to review this Privacy Policy periodically for
                  any changes. Changes to this Privacy Policy are effective when
                  they are posted on this page.
                </span>
              </p>
            </li>
            <li>
              <div className="legal-section-header">Contact Us</div>
              <p>
                <span>
                  If you have any questions about this Privacy Policy, please
                  contact us by email: team@hookshot.ca.
                </span>
              </p>
            </li>
          </ol>
        </div>
      </div>
    );
  }
}

export default PrivacyPage;
