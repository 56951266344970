import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import Button from '../elements/Button';

const propTypes = {
  ...SectionSplitProps.types
};

const defaultProps = {
  ...SectionSplitProps.defaults
};

class FeaturesSplit extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-split section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-split-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
      'split-wrap',
      invertMobile && 'invert-mobile',
      invertDesktop && 'invert-desktop',
      alignTop && 'align-top'
    );

    const sectionHeader = {
      title: 'Remove friction from your everyday workflows.',
      paragraph: ''
    };

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={splitClasses}>
              <div className="split-item">
                <div
                  className="split-item-content center-content-mobile reveal-from-left"
                  data-reveal-container=".split-item"
                >
                  <h3 className="mt-0 mb-12" id="video-clipper-section">Upload. Clip. Share.</h3>
                  <p className="m-0 mb-16">
                    Redefine your experience with video in Miro. Upload
                    recordings, generate transcriptions, and create playable
                    clips right in your board. A must-have for user interviews,
                    recorded meetings, and video-inclusive brainstorming.
                  </p>
                  <Button tag="a" color="primary" href="/easy-translator">
                    Learn more
                  </Button>
                </div>
                <div
                  className={classNames(
                    'split-item-image center-content-mobile illustration-element-03 reveal-from-right',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item"
                >
                  <div>
                    <video
                      autoPlay
                      loop
                      muted
                      playsInline
                      style={{ width: 550, height: 350 }}
                      src="https://customer-9qzbdpfg1n4t9u3r.cloudflarestream.com/3cd02e432146cd9e456a5b1e09435695/downloads/default.mp4"
                    ></video>
                  </div>
                </div>
              </div>

              <div className="split-item">
                <div
                  className="split-item-content center-content-mobile reveal-from-right"
                  data-reveal-container=".split-item"
                >
                  <h3 className="mt-0 mb-12">Super Search. And Replace.</h3>
                  <p className="m-0 mb-16">
                    Easily find what you're looking for in Miro and even filter
                    by selection, tags, colors, and more. Try our smart
                    find-and-replace that preserves links and custom text
                    formatting during replacement.
                  </p>
                  <Button tag="a" color="primary" href="/super-search">
                    Learn more
                  </Button>
                </div>
                <div
                  className={classNames(
                    'split-item-image center-content-mobile illustration-element-04 reveal-from-left',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item"
                >
                  <div>
                    <video
                      autoPlay
                      loop
                      muted
                      playsInline
                      style={{ width: 550, height: 350 }}
                      src="https://customer-9qzbdpfg1n4t9u3r.cloudflarestream.com/9aa0160b4db654ea85e331b41cb1cd3d/downloads/default.mp4"
                    ></video>
                  </div>
                </div>
              </div>

              <div className="split-item">
                <div
                  className="split-item-content center-content-mobile reveal-from-left"
                  data-reveal-container=".split-item"
                >
                  <h3 className="mt-0 mb-12">
                    Collaborate without borders.
                  </h3>
                  <p className="m-0 mb-16">
                    Translate your content from Figma, FigJam, and Miro into over
                    100 languages using Google's neural machine translation.
                    Prepare your designs for international users. Collaborate
                    with team members and clients around the world.
                  </p>
                  <Button tag="a" color="primary" href="/easy-translator">
                    Learn more
                  </Button>
                </div>
                <div
                  className={classNames(
                    'split-item-image center-content-mobile illustration-element-05 reveal-from-right',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item"
                >
                  <div>
                    <Image
                      src={require('./../../assets/images/easy-translator.gif')}
                      alt="easy translator"
                      style={{ width: 550 }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
