import React from 'react';
import HeroFullVideoClipper from '../components/sections/HeroFullVideoClipper';
import Button from '../components/elements/Button';
import SectionHeader from '../components/sections/partials/SectionHeader';

class VideoClipperPage extends React.Component {
  state = {
    demoModalActive: false
  };

  openModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: true });
  };

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: false });
  };

  render() {
    return (
      <React.Fragment>
        <HeroFullVideoClipper className="illustration-section-01" />
        <div className="container team section center-content">
          <div className="team-inner section-inner pt-0">
            <SectionHeader
              data={{
                title: 'Try Video Clipper for free today.'
              }}
              className="center-content"
              style={{ paddingBottom: 34 }}
            />
            <p>
              <Button
                tag="a"
                color="primary"
                wideMobile
                target="_blank"
                href="https://miro.com/marketplace/video-clipper/"
              >
                Install from the &nbsp;
                <img
                  src={require('./../assets/images/miro-logo.svg')}
                  alt="miro"
                  title="Available for Miro"
                />
                &nbsp; marketplace
              </Button>
            </p>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default VideoClipperPage;
