import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import Button from '../elements/Button';

const propTypes = {
  ...SectionTilesProps.types
};

const defaultProps = {
  ...SectionTilesProps.defaults
};

class FeaturesTiles extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-tiles section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-tiles-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames('tiles-wrap', pushLeft && 'push-left');

    const sectionHeader = {
      title: '',
      paragraph: ''
    };

    return (
      <section id="plugins" {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={tilesClasses}>
              <div className="tiles-item reveal-scale-up">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16 p-16">
                      <Image
                        src={require('./../../assets/images/video-clipper-logo.svg')}
                        alt="Video Clipper"
                        width={40}
                        height={40}
                      />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">Video Clipper</h4>
                    <p className="text-sm">
                      Upload videos, generate transcriptions, and create
                      playable clips right in Miro.
                    </p>
                    <Button tag="a" color="primary" href="/video-clipper">
                      Try Video Clipper
                    </Button>
                    <div className="features-tiles-item-platforms">
                      <img
                        src={require('./../../assets/images/miro-logo.svg')}
                        alt="miro"
                        title="Available for Miro"
                      />
                      <img
                        style={{ opacity: 0.2, cursor: 'not-allowed' }}
                        src={require('./../../assets/images/figma-logo.svg')}
                        alt="figma"
                        title="Not available for Figma"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="tiles-item reveal-scale-up"
                data-reveal-delay="200"
              >
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16 p-16">
                      <Image
                        src={require('./../../assets/images/super-search-logo-color.svg')}
                        alt="Super Search"
                        width={40}
                        height={40}
                      />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">Super Search</h4>
                    <p className="text-sm">
                      The search and replace functionality that you've been
                      missing in Miro.
                    </p>
                    <Button tag="a" color="primary" href="/super-search">
                      Try Super Search
                    </Button>
                    <div className="features-tiles-item-platforms">
                      <img
                        src={require('./../../assets/images/miro-logo.svg')}
                        alt="miro"
                        title="Available for Miro"
                      />
                      <img
                        style={{ opacity: 0.2, cursor: 'not-allowed' }}
                        src={require('./../../assets/images/figma-logo.svg')}
                        title="Not available for Figma"
                        alt="figma"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="tiles-item reveal-scale-up"
                data-reveal-delay="400"
              >
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16 p-12">
                      <Image
                        src={require('./../../assets/images/easy-translator-logo-color.svg')}
                        alt="Easy Translator"
                        width={45}
                        height={45}
                      />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">Easy Translator</h4>
                    <p className="text-sm">
                      Translate your mockups, designs, and brainstorming
                      sessions into over 100 languages.
                    </p>
                    <Button tag="a" color="primary" href="/easy-translator">
                      Try Easy Translator
                    </Button>
                    <div className="features-tiles-item-platforms">
                      <img
                        src={require('./../../assets/images/miro-logo.svg')}
                        alt="miro"
                        title="Available for Miro"
                      />
                      <img
                        src={require('./../../assets/images/figma-logo.svg')}
                        alt="figma"
                        title="Available for Figma"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
