import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Button from '../elements/Button';
import Image from '../elements/Image';

const propTypes = {
  ...SectionProps.types
};

const defaultProps = {
  ...SectionProps.defaults
};

class HeroFull extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'hero section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'hero-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    return (
      <section {...props} className={outerClasses}>
        <div className="container-sm">
          <div className={innerClasses}>
            <div className="hero-content">
              <h1
                className="mt-0 mb-16 reveal-from-bottom"
                data-reveal-delay="200"
              >
                We build premium plugins that fill the gaps in Miro and Figma.
              </h1>
              <div className="container-xs">
                <p
                  className="m-0 mt-32 mb-32 reveal-from-bottom"
                  data-reveal-delay="400"
                >
                  Join thousands of designers, researchers, and project managers
                  who use our plugins to improve their productivity and
                  collaboration.
                </p>
                {null && <div className="reveal-from-bottom" data-reveal-delay="600">
                  <Button tag="a" color="primary" href="/#plugins">
                    See plugins
                  </Button>
                </div>}
              </div>
            </div>
            {null && (
              <div
                className="hero-figure illustration-element-01 reveal-from-bottom"
                data-reveal-value="20px"
                data-reveal-delay="800"
              >
                <Image
                  className="has-shadow"
                  src={require('./../../assets/images/hero-image.png')}
                  alt="Hero"
                  width={896}
                  height={504}
                />
              </div>
            )}
          </div>
        </div>
      </section>
    );
  }
}

HeroFull.propTypes = propTypes;
HeroFull.defaultProps = defaultProps;

export default HeroFull;
